<!-- 店铺详情页-->
<template>
  <div class="viliageDetail w-1400">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right"><el-breadcrumb-item :to="{ path: '/xiangninghui' }">乡宁荟</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/xiangninghui/shopping' }">乡宁购</el-breadcrumb-item>
        <el-breadcrumb-item>{{ pageData.name }}</el-breadcrumb-item></el-breadcrumb>
    </div>
    <div class="face mt-20">
      <el-row :gutter="40">
        <el-col :span="11">
          <el-carousel trigger="click" height="500px">
            <el-carousel-item v-for="item in pageData.photoUrls.split(',')" :key="item + 'url'">
              <div class="face-pic">
                <el-image style="width: 100%; height: 100%" :src="item"></el-image>
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :span="13">
          <div class="face-inco">
            <div class="face-intro">
              <el-rate class="rate_star" v-model="pageData.evaluateStar" disabled show-score text-color="#ff9900" score-template="{value}分"></el-rate>
              <span>{{pageData.evaluateNumber}} 条评价</span>
            </div>
            <div class="face-phone f-label">电话：{{ pageData.telephone }}</div>
                <div class="intro-text mt-20"><h3>简介：</h3>
                <div>{{ pageData.introduction }}</div>
                </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div  class="face mt-20">
                 <!--特色美食-->
                <div class="food mt-20">
                    <div class="shopping-title">
                        <div class="fl h5">特色美食</div>
                        <div class="fr more"><router-link to="/foodlist"> 更多<i class="el-icon-arrow-right"></i></router-link></div>
                    </div>
                    <div class="food-main">
                        <el-row :gutter="15">
                            <el-col :span="8" v-for="item in foods" :key="item.id">
                                <router-link :to="`/fooddetail?id=${item.id}&type=1`">
                                    <div class="food-item">
                                        <div class="sj-ceng"></div>
                                        <div class="food-title ellipsis">{{item.name}}</div>
                                        <div class="text-ceng">
                                            <div>
                                                <p class="t1">{{item.name}}</p>
                                                <p class="t2">{{item.introduction}}</p>
                                            </div>
                                        </div>
                                        <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                                    </div>   
                                </router-link>
                            </el-col>
                        </el-row>
                        <div class="inner-text"></div>
                    </div>
                </div> 
    </div>

    <div class="others mt-20">
      <el-row :gutter="24">
        <el-col :span="16">
          <!--位置与配套-->
          <div class="house mt-20">
            <div class="others-title">
              <div class="fl h5">位置与配套</div>
            </div>
            <div class="position">
              <arc-map :title="pageData.name" :center="[pageData.longitude, pageData.latitude]"></arc-map>
              <!-- <baidu-map
                class="map"
                :center="{ lng: pageData.longitude, lat: pageData.latitude }"
                :scroll-wheel-zoom="true"
                :zoom="14"
              >
                <bm-marker
                  :position="{
                    lng: pageData.longitude,
                    lat: pageData.latitude,
                  }"
                  :dragging="true"
                >
                  <bm-label
                    :content="pageData.name"
                    :labelStyle="{
                      color: 'skyblue',
                      fontSize: '16px',
                      border: '1px solid skyblue',
                      transform: 'translateX(-48%)',
                    }"
                    :offset="{ width: 0, height: 30 }"
                  />
                </bm-marker>
              </baidu-map> -->
            </div>
          </div>
          <!--评价功能-->
          <evaluatiton :objectId="$route.query.id" :tableName="'t_s_sharedfarm'" :evaluateStar="pageData.evaluateStar" :keyList="keyList" @sum="getPageData"></evaluatiton>
        </el-col>
        <el-col :span="8">
          <!-- 附近住宿-->
          <div class="advertising mt-20">
            <div class="advertising-h3 clearfix">
              <div class="fl">附近热门住宿</div>
              <router-link to="xiangninghui/sharehouse">
              <div class="fr more">更多<i class="el-icon-arrow-right"></i></div>
              </router-link>
            </div>
            <div class="advertising-main">
              <div
                class="advertising-itme clearfix"
                v-for="item in SharedFarmPageData"
                :key="item.id + 'near'"
              >
                  <div class="advertising-itme-pic fl">
                     <router-link :to="`/redirect?id=${item.id}&path=/sharehousedetail`">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls.split(',')[0]"
                  ></el-image>
                  </router-link>
                  </div>
                <div class="item-info fr">
                  <div class="name">名称：{{ item.name }}</div>
                  <div class="score">等级：{{ item.villageType }}</div>
                </div>
              </div>
            </div>
          </div>
                 <!-- 附近景点-->
          <div class="advertising mt-20">
            <div class="advertising-h3 clearfix">
              <div class="fl">附近热门景点</div>
              <router-link to="/scenerySpotList">
                <div class="fr more">
                  更多<i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
            </div>
            <div class="advertising-main">
              <div
                class="advertising-itme clearfix"
                v-for="item in ScenerySpotPageData"
                :key="item.id + 'near'"
              >
                <div class="advertising-itme-pic fl">
                    <router-link  :to="`/scenerySpotdetail?id=${item.id}`">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls.split(',')[0]"
                  ></el-image>
                  </router-link>
                </div>
                <div class="item-info fr">
                  <div class="name">名称：{{ item.name }}</div>
                  <div class="score">星级：{{ item.grade }}</div>
                  <div class="price buy">价格：{{ item.price }}元</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {GetSharedFarm,GetVillagePage,GetScenerySpotPage,GetSharedFarmPage,GetFoodPage} from "@/api/home";
import evaluatiton from '../../../components/xiangninghui/evaluatiton.vue';
export default {
  components:{evaluatiton},
  data() {
    return {
      pageData: { photoUrls: "" },
      params: {
        currentPage: 1,
        pageSize: 3,
        filter: {
          businessType: "精品民宿",
        },
      },
      pageData: {},
      SharedFarmPageData: {},
      ScenerySpotPageData: {},
      VillagePageData: {},
    //   foodList:[
    //             // {name:'',img:'',intro:''},
    //         ],
     foods: [], //特色美食
     keyList:{
        '还会再来':0,
        '性价比高':0,
        '环境优美':0,
        '干净整洁':0,
        '服务好':0,
      },
    };
  },
  methods: {
    getPageData() {
      GetSharedFarm(this.$route.query.id).then((res) => {
        this.pageData = res.data.data;
        this.devList = this.devList.filter((item) =>
          res.data.data.facilities.split(",").includes(item.name)
        );
      });
    },
    getSharedFarmPage() {
      GetSharedFarmPage(this.params).then((res) => {
        this.SharedFarmPageData = res.data.data.list;
      });
    },
    getScenerySpotPage() {
      GetScenerySpotPage(this.params).then((res) => {
        this.ScenerySpotPageData = res.data.data.list;
      });
    },
    getVillagePage() {
      GetVillagePage(this.params).then((res) => {
        this.VillagePageData = res.data.data.list;
      });
    },
    getFoods(){
            const data = { currentPage:1, pageSize:3};
            GetFoodPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.foods = data;
                }
            })
        },
  },
  mounted() {
    this.getPageData();
    this.getSharedFarmPage();
    this.getScenerySpotPage();
    this.getVillagePage();
     this.getFoods();
  },
};
</script>
<style scoped>
.rate_star{
  margin-right: 30px;
}
.map {
  height: 500px;
}
.isHas {
  background: #78bff8;
}
.dev-list {
  width: 90px;
  padding-bottom: 10px;
  color: #fff;
  margin-right: 16px;
  margin-bottom: 10px;
  border-radius: 3px;
}
.dev-main.warp {
  flex-wrap: wrap;
  justify-content: left;
}
.zc span {
  color: #777;
}
.icon-box {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
}
.dev-main {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}
.icon-box .iconfont {
  font-size: 40px;
  width: 40px;
  height: 40px;
}
.position {
  padding: 12px;
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.path {
  padding-left: 30px;
}
.path p {
  height: 40px;
  line-height: 40px;
  color: #999;
  font-size: 16px;
}
.others .house-item {
  border: 1px solid #ccc;
  margin-top: 15px;
}
.house-price {
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #f9773b;
  text-align: right;
  padding-right: 12px;
}
.house-intro {
  font-size: 16px;
  line-height: 20px;
  text-indent: 24px;
  height: 60px;
  color: #777;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.house-h5 {
  font-size: 18px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
}
.house-info {
  padding: 10px;
}
.house-pic {
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.others-title .more:hover {
  color: #1c91ff;
}
.others-title .h5 {
  font-size: 22px;
  color: #0169ca;
}
.others-title {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #ccc;
}
.market2 span:nth-child(1) {
  border: 1px solid #f9773b;
  color: #f9773b;
}
.market2 span:nth-child(2) {
  border: 1px solid #1c91ff;
  color: #1c91ff;
}
.market2 span:nth-child(3) {
  border: 1px solid #33d448;
  color: #33d448;
}
.market2 span {
  padding: 0 8px;
  margin-right: 12px;
}
.score {
  color: #f9773b;
}
.market span {
  padding: 0 8px;
  background: #1c91ff;
  color: #fff;
  margin-left: 12px;
}
.item-info div.buy {
  color: #0169ca;
}
.item-info div {
  height: 29px;
  line-height: 29px;
  font-size: 16px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advertising-main {
  padding: 0 10px;
  border: 1px solid #ccc;
}
.more:hover {
  color: #eee;
}
.more {
  cursor: pointer;
  font-size: 16px;
}
.advertising-h3 {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  background: #0169ca;
  padding: 0 6px;
}
.advertising-itme {
  margin-top: 12px;
  overflow: hidden;
  width: 100%;
  height: 120px;
}
.advertising-itme-pic {
  width: 170px;
  height: 114px;
}
.item-info {
  width: calc(100% - 180px);
}
.intro-text div {
  font-size: 16px;
  text-indent: 20px;
  line-height: 1.5;
  /* display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}
.intro-text h3 {
  height: 42px;
  line-height: 42px;
  font-size: 20px;
  font-weight: 600;
}
.intro-text {
  border: 1px solid #ccc;
  padding: 20px 10px;
}
.face-has li {
  background: #0169ca;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  margin-right: 30px;
}
.face-has ul {
  padding-left: 30px;
  width: 500px;
}
.face-has li {
  margin-top: 20px;
}
.face-intro{
  display: flex;
}
.face-intro,
.face-has {
  margin: 20px 0;
}
.face-intro span:last-child {
  font-size: 16px;
  color: #1c91ff;
}
.face-intro span:first-child {
  color: #f9773b;
  padding: 10px 0px;
  font-size: 20px;
  margin-right: 20px;
}
.viliageDetail {
  font-family: "微软雅黑";
}
.f-label {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.face-pic >>> .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.face-pic {
  height: 500px;
  width: 100%;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
.card2-intro{
    text-indent: 20px;
    font-size: 16px;
    line-height: 20px;
    min-height: 60px;
    font-size: '微软雅黑';
    text-overflow: -o-elllipsis-lastline;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
    
}
.card-name{
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
}
.card2-name{
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
}
.card2-info{
    height: 110px;
    overflow: hidden;
}
.card-info{
    width: calc(100% - 255px);
    margin-left: 18px;
}
.card-info span{
    color:#999
}
.card2-info{
    width: calc(100% - 150px);
    margin-left: 18px;
}
.card-pic{
    width: 235px;
    height: 175px;
    overflow: hidden;
}
.card2-pic{
    width: 130px;
    height: 110px;
    overflow: hidden;
}
.card2{
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.card2~.card2{
    border-top: 0;
}
.food-item:hover .text-ceng{
     /* width: 100%; */
    opacity: 1; 
    left: 0;
}
.text-ceng>div .t1{
    text-align: center;
    line-height: 36px;

}
.text-ceng>div{
    display: table-cell;
    vertical-align: middle;
    padding: 20px;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
    height: 100%;
}
.text-ceng{
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 4;
    /* padding:10px; */
    background: rgba(0, 0, 0, .5);
    color: #fff;
    /* height: 0; */
    opacity: 0;
    overflow: hidden;
    display: table;
    transition: all 1.2s;
}
.food-main{
    position: relative;
}
.sj-ceng{
    /* border-top: 50px solid #ff4761; */
    border-right: 270.4px solid transparent;
    border-bottom: 60px solid #ff4761;
    /* border-right: 100% solid transparent; */
    position: absolute;
    left: 0;
    bottom: 0;
    /* box-sizing: content-box; */
    z-index: 2;
}
.foods-classify-item.actt,.foods-classify-item:hover{
    color: #fff;
    background: #0169ca;
}
.foods-classify-item{
    height: 46px;
    line-height: 46px;
    border-radius: 10px;
    font-size: 22px;
    background: #eee;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
}
.foods-others-name>p:first-child.bg-2{
    background: #ffae00;
    color: #fff;
}
.foods-others-name>p:first-child.bg-3{
    background: #0169ca;
    color: #fff;
}
.foods-item{
    padding: 12px 0;
    border-bottom: 1px solid #ccc;
}
.foods-item:last-child{
    border:0;
}
.foods-others-sortnum>p:first-child.c-2{
    color: #ffae00;
}
.foods-others-sortnum>p:first-child{
    color: #1c91ff;
}
.foods-others-sortnum>p{
    font-size: 14px;
    line-height: 14px;
    text-align: center;
}
.foods-others-name>p:first-child{
    font-size: 14px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: #ddd;
    text-align: center;
    margin-right: 5px;
    box-sizing: border-box;
    transform: translateY(4px);
}
.foods-others-inner{
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 600;
    padding:0 4px;
    color: #666;
}
.foods-top1-name .lift p{
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
}
.foods-top1-name{
    font-size: 20px;
    height: 44px;
    line-height: 44px;
    font-weight: 700;
}
.foods-top10-h3 .iconfont{
    font-size: 22px;
}
.foods-top10{
    border: 2px solid #ccc;
}
.foods-top10-h3{
    height: 42px;
    line-height: 42px;
    font-size: 22px;
    color: #0169ca;
    font-weight: 600;
}
.foods-top1-pic .sortnum{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    text-align:center ;
    line-height: 20px;
    background: #f9773b;
    color: #fff;
}
.foods-top1-info{
    width: calc(100% - 158px);
    font-size: 15px;
    line-height: 1.5;
    font-family: '微软雅黑';
}
.foods-top1-pic{
    width: 130px;
    height: 110px;
    overflow: hidden;
    position: relative;
}
.foods-top1{
    padding: 12px 4px;
    border-bottom: 1px solid #ccc;
    /* height: 110px; */
}
.shop-text>p:last-child{
    font-size: 16px;
    padding: 5px 0;
    font-weight: 600;
    text-indent: 18px;
    line-height: 28px;
}
.shop-text>p:first-child{
    font-size: 20px;
    padding: 5px 0;
    font-weight: 700;
}
.shop-text{
    text-indent: 20px;
    font-size: 16px;
    line-height: 20px;
    min-height: 60px;
    font-size: '微软雅黑';
    text-overflow: -o-elllipsis-lastline;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:6;
    -webkit-box-orient:vertical;
}
.shop-info{
    width: calc(100% - 225px);
    height: 180px;
    display: table;
}
.shop-pic{
    width: 210px;
    height: 180px;
    overflow: hidden;
}
.shop-item-innerbox{
    padding: 10px;
}
.shop-item:hover .shop-item-innerbox{
    border: 1px solid #ccc;
    color:#fff
}
.shop-item:hover{
    border: 0;
    background: #0169ca;
}
.shop-item{
    height: 220px;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
    transition: background 0.8s;
}
.food-item .food-title{
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
    color: #fff;
    width: 50%;
    /* background: rgba(0, 0, 0, 0.7); */
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
}
.food-item{
    width: 100%;
    height: 240px;
    overflow: hidden;
    position: relative;
    margin-top: 15px;
}
.btn-prev{
    left: 0;
}
.btn-next{
    right: 0;
}
.btn-prev.disabled,.btn-prev.disabled:hover,.btn-next.disabled,.btn-next.disabled:hover{
    color: #ccc;
}
.btn-prev:hover,.btn-next:hover{
    color: #1c91ff;
    background: #f1f1f1;
}
.btn-prev,.btn-next{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    color: #0169ca;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    cursor: pointer;
}
.carousel-item-info .price{
    color:#f9773b;
}
.carousel-item-info p{
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-family: '黑体';
}
.carousel-inner{
    width: 1800px;
    transition: transform 1.5s;
}
.carousel-item-info{
    width: 145px;
    height: 90px;
}
.carousel-item-pic{
    width: 105px;
    height: 90px;
}
.carousel-item{
    width: 270px;
    height: 150px;
    margin-right: 15px;
    background: #eee;
    padding: 30px 5px;
    box-sizing: border-box;
}
.carousel-box{
    height: 150px;
    width: 840px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.feature-carousel{
    height: 150px;
    width: 100%;
    position: relative;
}
.feature-small{
    width: 100%;
    height: 220px;
}
.mt-10{
    margin-top: 10px;
}
.feature-picbig >>> .el-carousel__button{
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.mt-20{
    margin-top: 20px;
}
.feature-picbig{
    width: 100%;
    height: 450px;
}
.shopping-title .h5{
    color:#0169ca;
}
.shopping-title{
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
}
.shopping-title .more:hover{
    color: #1c91ff;
    cursor: pointer;
}
</style>
